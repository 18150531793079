import { Form } from "antd";
import { Row, Col } from "react-bootstrap";
import { withTranslation } from "react-i18next";
import { H3 } from "../../../../components/Typography";
import { connect } from "react-redux";
import {
  ERivestimentoVanoDoccia,
  EStrutturaVanoDoccia,
  getLabelEStrutturaVanoDoccia,
  getLabelERivestimentoVanoDoccia,
  TStrutturaVanoDocciaStoreState,
} from "../../../../redux/reducers/struttura_vano_doccia";
import { TGeneral } from "../../../../redux/reducers";
import { useDispatch, useStore, useSelector } from "react-redux";
import {
  getCrmLanguage,
  getLocalization,
  isKitCoibentazioneVisible,
  Localization,
} from "../../../../utils/utils";
import {
  actGeneratoreSetModel,
  actStrutturaVanoDocciaSetData,
  actGeneratoreSetOptionals
} from "../../../../redux/actions";
import { useEffect, useState } from "react";
import Modal, { ModalSize } from "../../../../components/Modal";
import AsyncSelect from "../../../../components/AsyncSelect";
import tailwindMyConfig from "../../../../tailwind.my.config";
import TextCell from "../../../../components/AsyncSelect/TextCell";
import kit_coibentazione_1 from "../../../../assets/images/kit_coibentazione/kit_1.png";
import kit_coibentazione_2 from "../../../../assets/images/kit_coibentazione/kit_2.png";
import kit_coibentazione_materiali_1 from "../../../../assets/images/kit_coibentazione/kit_3.png";
import kit_coibentazione_materiali_2 from "../../../../assets/images/kit_coibentazione/kit_4.png";
import kit_coibentazione_materiali_3 from "../../../../assets/images/kit_coibentazione/kit_5.png";
import kit_coibentazione_materiali_4 from "../../../../assets/images/kit_coibentazione/kit_6.png";
import { getGeneratoriInterni, getOptional } from "../../../../network/actions";
import { LIMIT, OPTIONALS_PANNELLO_CONTROLLO, OPTIONALS_SMART_POWER, getLanguageForNSP } from "../../../../utils/consts";
import Subtitle from "../../../../components/Typography/Subtitle";
import { StepConfiguratore, StepErrorField } from "../..";
import MsgError from "../../../../components/MsgError";
import { TOptionalDetailType } from "../../../../redux/reducers/optional";

function StrutturaVanoDoccia({ ...props }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const store = useStore();
  const struttura_vano_doccia: TStrutturaVanoDocciaStoreState = useSelector(
    (state: TGeneral) => state.struttura_vano_doccia
  );
  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});

  const openModal = (title: string, content: any, size: any) => {
    setModalData({ title, content, size });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };

  const downloadGeneratoriInterni = async () => {
    try {
      const listGeneratoriInterni = await getGeneratoriInterni(0, LIMIT);
      dispatch(
        actGeneratoreSetModel({
          list_generatori_interni: listGeneratoriInterni,
        })
      );
    } catch (error) { }
  };

  const downloadNSPOptionalsRequired = async () => {
    try {
      let optionalsDiffusorsForNSP:{[key: string]: {
        GLASS: TOptionalDetailType | undefined,
        CAMOUFLAGE: TOptionalDetailType | undefined,
        TRADITIONAL_CROMO: TOptionalDetailType | undefined,
        TRADITIONAL_NERO: TOptionalDetailType | undefined,
        TRADITIONAL_GUN: TOptionalDetailType | undefined
      }} = {
        "1PZ":{
          GLASS: undefined,
          CAMOUFLAGE: undefined,
          TRADITIONAL_CROMO: undefined,
          TRADITIONAL_NERO: undefined,
          TRADITIONAL_GUN: undefined
        },
        "2PZ":{
          GLASS: undefined,
          CAMOUFLAGE: undefined,
          TRADITIONAL_CROMO: undefined,
          TRADITIONAL_NERO: undefined,
          TRADITIONAL_GUN: undefined
        },
        "4PZ":{
          GLASS: undefined,
          CAMOUFLAGE: undefined,
          TRADITIONAL_CROMO: undefined,
          TRADITIONAL_NERO: undefined,
          TRADITIONAL_GUN: undefined
        },
        "8PZ":{
          GLASS: undefined,
          CAMOUFLAGE: undefined,
          TRADITIONAL_CROMO: undefined,
          TRADITIONAL_NERO: undefined,
          TRADITIONAL_GUN: undefined
        }
      }
      let optionalsControlPanelForNSP:TOptionalDetailType[] = []

      //Diffusor
      console.log('OPTIONALS_SMART_POWER["1PZ"].GLASS[getLanguageForNSP()]',getLanguageForNSP(),OPTIONALS_SMART_POWER["1PZ"].GLASS[getLanguageForNSP()])
      optionalsDiffusorsForNSP["1PZ"].GLASS = await getOptional(OPTIONALS_SMART_POWER["1PZ"].GLASS[getLanguageForNSP()])
      optionalsDiffusorsForNSP["1PZ"].CAMOUFLAGE = await getOptional(OPTIONALS_SMART_POWER["1PZ"].CAMOUFLAGE[getLanguageForNSP()])
      optionalsDiffusorsForNSP["1PZ"].TRADITIONAL_CROMO = await getOptional(OPTIONALS_SMART_POWER["1PZ"].TRADITIONAL_CROMO[getLanguageForNSP()])
      optionalsDiffusorsForNSP["1PZ"].TRADITIONAL_GUN = await getOptional(OPTIONALS_SMART_POWER["1PZ"].TRADITIONAL_GUN[getLanguageForNSP()])
      optionalsDiffusorsForNSP["1PZ"].TRADITIONAL_NERO = await getOptional(OPTIONALS_SMART_POWER["1PZ"].TRADITIONAL_NERO[getLanguageForNSP()])

      optionalsDiffusorsForNSP["2PZ"].GLASS = await getOptional(OPTIONALS_SMART_POWER["2PZ"].GLASS[getLanguageForNSP()])
      optionalsDiffusorsForNSP["2PZ"].CAMOUFLAGE = await getOptional(OPTIONALS_SMART_POWER["2PZ"].CAMOUFLAGE[getLanguageForNSP()])
      optionalsDiffusorsForNSP["2PZ"].TRADITIONAL_CROMO = await getOptional(OPTIONALS_SMART_POWER["2PZ"].TRADITIONAL_CROMO[getLanguageForNSP()])
      optionalsDiffusorsForNSP["2PZ"].TRADITIONAL_GUN = await getOptional(OPTIONALS_SMART_POWER["2PZ"].TRADITIONAL_GUN[getLanguageForNSP()])
      optionalsDiffusorsForNSP["2PZ"].TRADITIONAL_NERO = await getOptional(OPTIONALS_SMART_POWER["2PZ"].TRADITIONAL_NERO[getLanguageForNSP()])

      optionalsDiffusorsForNSP["4PZ"].GLASS = await getOptional(OPTIONALS_SMART_POWER["4PZ"].GLASS[getLanguageForNSP()])
      optionalsDiffusorsForNSP["4PZ"].CAMOUFLAGE = await getOptional(OPTIONALS_SMART_POWER["4PZ"].CAMOUFLAGE[getLanguageForNSP()])
      optionalsDiffusorsForNSP["4PZ"].TRADITIONAL_CROMO = await getOptional(OPTIONALS_SMART_POWER["4PZ"].TRADITIONAL_CROMO[getLanguageForNSP()])
      optionalsDiffusorsForNSP["4PZ"].TRADITIONAL_GUN = await getOptional(OPTIONALS_SMART_POWER["4PZ"].TRADITIONAL_GUN[getLanguageForNSP()])
      optionalsDiffusorsForNSP["4PZ"].TRADITIONAL_NERO = await getOptional(OPTIONALS_SMART_POWER["4PZ"].TRADITIONAL_NERO[getLanguageForNSP()])

      optionalsDiffusorsForNSP["8PZ"].GLASS = await getOptional(OPTIONALS_SMART_POWER["8PZ"].GLASS[getLanguageForNSP()])
      optionalsDiffusorsForNSP["8PZ"].CAMOUFLAGE = await getOptional(OPTIONALS_SMART_POWER["8PZ"].CAMOUFLAGE[getLanguageForNSP()])
      optionalsDiffusorsForNSP["8PZ"].TRADITIONAL_CROMO = await getOptional(OPTIONALS_SMART_POWER["8PZ"].TRADITIONAL_CROMO[getLanguageForNSP()])
      optionalsDiffusorsForNSP["8PZ"].TRADITIONAL_GUN = await getOptional(OPTIONALS_SMART_POWER["8PZ"].TRADITIONAL_GUN[getLanguageForNSP()])
      optionalsDiffusorsForNSP["8PZ"].TRADITIONAL_NERO = await getOptional(OPTIONALS_SMART_POWER["8PZ"].TRADITIONAL_NERO[getLanguageForNSP()])
      //Panel
      optionalsControlPanelForNSP.push(await getOptional(OPTIONALS_PANNELLO_CONTROLLO.INTERNO[getLanguageForNSP()])) 
      optionalsControlPanelForNSP.push(await getOptional(OPTIONALS_PANNELLO_CONTROLLO.ESTERNO[getLanguageForNSP()])) 
      console.log("actGeneratoreSetOptionals",{optionalsDiffusorsForNSP})

      dispatch(
        actGeneratoreSetOptionals({
          listNSPControlPanels: optionalsControlPanelForNSP,
          listNSPSteamDiffusors: optionalsDiffusorsForNSP
        })
      );
      
    } catch (error) { 
      console.log("[ERROR - downloadNSPOptionalsRequired]", error)
    }
  }

  /**
   * Download di informazioni allo startup dell'app
   */
  useEffect(() => {
    downloadGeneratoriInterni();
    downloadNSPOptionalsRequired();
  }, []);

  return (
    <Form form={form} name="basic" layout={"vertical"} autoComplete={"off"}>
      {getLocalization() === Localization.EUROPA && (
        <>
          <H3
            title={props.t("vanodoccia_struttura.struttura.title")}
            className="text-left text-black-effe m-title"
          />

          <Row>
            <Col md={12} lg={12}>
              <Form.Item name="struttura"
                className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "struttura")?.id && "error-field"}`}>
                <div className="container-input">
                  <AsyncSelect
                    currValue={
                      struttura_vano_doccia?.struttura
                        ? {
                          name: props.t(
                            getLabelEStrutturaVanoDoccia(
                              struttura_vano_doccia?.struttura
                            )
                          ),
                          id: struttura_vano_doccia?.struttura,
                        }
                        : null
                    }
                    placeholder={props.t("general.scegli_opzione")}
                    onChange={(val: any) => {
                      let _struttura: TStrutturaVanoDocciaStoreState = {
                        struttura: val.id,
                      };
                      if (val.id === EStrutturaVanoDoccia.Poliestirene)
                        _struttura = {
                          ..._struttura,
                          kit_coibentazione: false,
                        };
                      dispatch(actStrutturaVanoDocciaSetData(_struttura));
                    }}
                    onStyleLabel={(item: any) => {
                      return <TextCell title={item.name} />;
                    }}
                    loadOptions={async (
                      searchQuery: any,
                      loadedOptions: any,
                      { page }: any
                    ) => {
                      const response: string[] =
                        Object.keys(EStrutturaVanoDoccia);
                      return {
                        options: response.map((val: string) => {
                          return {
                            id: String(val).toLowerCase(),
                            name: props.t(
                              getLabelEStrutturaVanoDoccia(
                                val as EStrutturaVanoDoccia
                              )
                            ),
                          };
                        }),
                        hasMore: false,
                        additional: {},
                      };
                    }}
                  />
                </div>
              </Form.Item>
            </Col>
          </Row>
        </>
      )}

      <H3
        title={props.t("vanodoccia_struttura.rivestimento.title")}
        className="text-left text-black-effe m-title"
      />
      <Row>
        <Col lg={12}>
          <Form.Item name="rivestimento"
            className={`${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "rivestimento")?.id && "error-field"}`}>
            <div className="container-input">
              <AsyncSelect
                currValue={
                  struttura_vano_doccia?.rivestimento
                    ? {
                      name: props.t(
                        getLabelERivestimentoVanoDoccia(
                          struttura_vano_doccia?.rivestimento
                        )
                      ),
                      id: struttura_vano_doccia?.rivestimento,
                    }
                    : null
                }
                placeholder={props.t("general.scegli_opzione")}
                onChange={(val: any) => {
                  dispatch(
                    actStrutturaVanoDocciaSetData({
                      rivestimento: val.id,
                    })
                  );
                }}
                onStyleLabel={(item: any) => {
                  return <TextCell title={item.name} />;
                }}
                loadOptions={async (
                  searchQuery: any,
                  loadedOptions: any,
                  { page }: any
                ) => {
                  const response: string[] = Object.keys(
                    ERivestimentoVanoDoccia
                  );
                  return {
                    options: response.map((val: string) => {
                      return {
                        id: String(val).toLowerCase(),
                        name: props.t(
                          getLabelERivestimentoVanoDoccia(
                            val as ERivestimentoVanoDoccia
                          )
                        ),
                      };
                    }),
                    hasMore: false,
                    additional: {},
                  };
                }}
              />
            </div>
          </Form.Item>
        </Col>
      </Row>

      {struttura_vano_doccia?.struttura !== EStrutturaVanoDoccia.Poliestirene &&
        isKitCoibentazioneVisible() && (
          <>
            <H3
              title={props.t(
                "vanodoccia_struttura.kit_coibentazione.title"
              )}
              className="text-left text-black-effe m-title mb-0"
            />
            <button
              onClick={() =>
                openModal(
                  props.t(
                    "vanodoccia_struttura.kit_coibentazione.modal.title"
                  ),
                  () => {
                    return (
                      <div>
                        <Row>
                          <Col lg={4}>
                            <div className="flex flex-col items-center">
                              <img
                                src={kit_coibentazione_1}
                                className="img-fluid w-full"
                                style={{
                                  maxHeight: 300,
                                  maxWidth: 450,
                                  objectFit: "contain",
                                }}
                              />
                              <img
                                src={kit_coibentazione_2}
                                className="img-fluid w-full mt-4 mb-8"
                                style={{
                                  maxHeight: 300,
                                  maxWidth: 450,
                                  objectFit: "contain",
                                }}
                              />
                            </div>
                          </Col>
                          <Col lg={8}>
                            <Row>
                              <Col lg={6}>
                                <p
                                  style={{
                                    fontSize: 12,
                                    color:
                                      tailwindMyConfig.theme.extend.colors[
                                      "fluo-effe"
                                      ],
                                  }}
                                >
                                  <strong>EACH KIT INCLUDES:</strong> <br />
                                  <p>
                                    panels size mm 600x1500 x 12.5 / 2' x 8' x
                                    1/2'' Sandwich panels with extruded
                                    polystyrene core with mortar reinforced
                                    with fibreglass mesh on both sides, can be
                                    covered with tiles equipped with fischer
                                    wall plugs and includes:
                                  </p>
                                  <ul style={{ listStyle: "inside" }}>
                                    <li>Vapor 85 by Wedi</li>
                                    <li>Screws and Washers</li>
                                    <li>Sealing Tape</li>
                                    <li>Wedi 620 Sealant</li>
                                    <li>Subliner Dry inside corner;</li>
                                    <li>Fexi collar</li>
                                    <li>Sealing Collar</li>
                                  </ul>
                                  <strong>INSULATION KIT</strong> <br />
                                  <strong>
                                    (PANELS + SCREW/WASHER + GLUE + VAPOR
                                    BARRIER)*
                                  </strong>{" "}
                                  <br />
                                  <table className="w-full">
                                    <tbody className="w-full font-700">
                                      <tr className="border border-black-effe border-b bg-grigio-chiaro-effe text-black-effe">
                                        <td className="p-1">
                                          01 - UP TO 128 SQFT
                                        </td>
                                        <td className="p-1">Cod: PW900128</td>
                                        <td className="p-1">$ 2,225.00</td>
                                      </tr>

                                      <tr className="border border-black-effe border-b bg-grigio-chiaro-effe text-black-effe">
                                        <td className="p-1">
                                          02 - UP TO 192
                                        </td>
                                        <td className="p-1">Cod: PW900192</td>
                                        <td className="p-1">$ 3,150.00</td>
                                      </tr>

                                      <tr className="border border-black-effe border-b bg-grigio-chiaro-effe text-black-effe">
                                        <td className="p-1">
                                          03 - UP TO 288
                                        </td>
                                        <td className="p-1">Cod: PW900228</td>
                                        <td className="p-1">$ 4,225.00</td>
                                      </tr>
                                    </tbody>
                                  </table>
                                  <p>
                                    * Please indicate on PO if screws must be
                                    for wood or metal studs. If no indication
                                    we will provide screws for metal studs.
                                  </p>
                                </p>
                              </Col>

                              <Col lg={6}>
                                <Row>
                                  <Col lg={12} md={6} sm={6} xs={6}>
                                    <img
                                      src={kit_coibentazione_materiali_1}
                                      className="img-fluid w-full mt-4"
                                      style={{
                                        maxHeight: 100,
                                        maxWidth: 180,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12} md={6} sm={6} xs={6}>
                                    <img
                                      src={kit_coibentazione_materiali_2}
                                      className="img-fluid w-full mt-4"
                                      style={{
                                        maxHeight: 100,
                                        maxWidth: 180,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12} md={6} sm={6} xs={6}>
                                    <img
                                      src={kit_coibentazione_materiali_3}
                                      className="img-fluid w-full mt-4"
                                      style={{
                                        maxHeight: 100,
                                        maxWidth: 180,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Col>
                                  <Col lg={12} md={6} sm={6} xs={6}>
                                    <img
                                      src={kit_coibentazione_materiali_4}
                                      className="img-fluid w-full mt-4"
                                      style={{
                                        maxHeight: 100,
                                        maxWidth: 180,
                                        objectFit: "contain",
                                      }}
                                    />
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    );
                  },
                  ModalSize.xl
                )
              }
            > 
            {
              getLocalization()===Localization.AMERICA ? <Subtitle
              title={props.t("vanodoccia_struttura.kit_coibentazione.info")}
            /> : null
            }
              
            </button>
            <Row>
              <Col lg={12}>
                <Form.Item name="kit_coibentazione"
                  className={`mb-0 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "kit_coibentazione")?.id && "error-field"}`}>
                  <div className="container-input">
                    <AsyncSelect
                      className={`${struttura_vano_doccia?.kit_coibentazione === false &&
                        "warning-input"
                        }
                  ${struttura_vano_doccia?.kit_coibentazione === true &&
                        "success-input"
                        }`}
                      currValue={
                        struttura_vano_doccia?.kit_coibentazione !== undefined
                          ? {
                            name: props.t(
                              struttura_vano_doccia?.kit_coibentazione
                                ? "general.si"
                                : "general.no"
                            ),
                            id: struttura_vano_doccia?.kit_coibentazione,
                          }
                          : null
                      }
                      placeholder={props.t("general.scegli_opzione")}
                      onChange={(val: any) => {
                        dispatch(
                          actStrutturaVanoDocciaSetData({
                            kit_coibentazione: val.id,
                          })
                        );
                      }}
                      onStyleLabel={(item: any) => {
                        return <TextCell title={item.name} />;
                      }}
                      loadOptions={async (
                        searchQuery: any,
                        loadedOptions: any,
                        { page }: any
                      ) => {
                        const response: boolean[] = [true, false];
                        return {
                          options: response.map((val: boolean) => {
                            return {
                              id: val,
                              name: props.t(val ? "general.si" : "general.no"),
                            };
                          }),
                          hasMore: false,
                          additional: {},
                        };
                      }}
                    />
                  </div>
                </Form.Item>
                {struttura_vano_doccia?.kit_coibentazione !== undefined && (
                  <p
                    className="font-regular"
                    style={{
                      textAlign: "left",
                      fontSize: 16,
                      color: tailwindMyConfig.theme.extend.colors[struttura_vano_doccia?.kit_coibentazione ? "fluo-effe" : "warning-input-effe"],
                    }}
                  >
                    {props.t(
                      struttura_vano_doccia?.kit_coibentazione ? "vanodoccia_struttura.kit_coibentazione.kit_coibentazione.success" : "vanodoccia_struttura.kit_coibentazione.kit_coibentazione.warning"
                    )}
                  </p>
                )}

              </Col>

            </Row>
          </>
        )}


      {struttura_vano_doccia?.struttura !== EStrutturaVanoDoccia.Poliestirene &&
        isKitCoibentazioneVisible() && struttura_vano_doccia?.kit_coibentazione === true && getLocalization()===Localization.AMERICA && (
          <>
            <H3
              title={props.t(
                "v7.add_kit_coibentazione_to_quote"
              )}
              className="text-left text-black-effe m-title mt-2"
            />

            <Row>
              <Col lg={12}>
                <Form.Item name="add_kit_coibentazione_to_quote"
                  className={`mb-0 ${store.getState()?.general?.stepError?.fields?.find((s: StepErrorField) => s.id === "add_kit_coibentazione_to_quote")?.id && "error-field"}`}>
                  <div className="container-input">
                    <AsyncSelect
                      className={`${struttura_vano_doccia?.add_kit_coibentazione_to_quote === false &&
                        "warning-input"
                        }
                  ${struttura_vano_doccia?.add_kit_coibentazione_to_quote === true &&
                        "success-input"
                        }`}
                      currValue={
                        struttura_vano_doccia?.add_kit_coibentazione_to_quote !== undefined
                          ? {
                            name: props.t(
                              struttura_vano_doccia?.add_kit_coibentazione_to_quote
                                ? "general.si"
                                : "general.no"
                            ),
                            id: struttura_vano_doccia?.add_kit_coibentazione_to_quote,
                          }
                          : null
                      }
                      placeholder={props.t("general.scegli_opzione")}
                      onChange={(val: any) => {
                        dispatch(
                          actStrutturaVanoDocciaSetData({
                            add_kit_coibentazione_to_quote: val.id,
                          })
                        );
                      }}
                      onStyleLabel={(item: any) => {
                        return <TextCell title={item.name} />;
                      }}
                      loadOptions={async (
                        searchQuery: any,
                        loadedOptions: any,
                        { page }: any
                      ) => {
                        const response: boolean[] = [true, false];
                        return {
                          options: response.map((val: boolean) => {
                            return {
                              id: val,
                              name: props.t(val ? "general.si" : "general.no"),
                            };
                          }),
                          hasMore: false,
                          additional: {},
                        };
                      }}
                    />
                  </div>
                </Form.Item>

              </Col>

            </Row>
          </>
        )}


      {
        store.getState()?.general?.stepError?.step === StepConfiguratore.StrutturaVanoDoccia &&
        <Row>
          <Col className="mt-8">
            <MsgError message={store.getState()?.general?.stepError?.msg} />
          </Col>
        </Row>
      }

      <Modal
        title={modalData.title}
        onClose={() => closeModal()}
        visible={modalIsOpen}
        size={modalData.size}
      >
        {modalData.content && modalData.content()}
      </Modal>
    </Form>
  );
}

export default connect((state: TGeneral, ownProps) => ({
  general: state.general
}), {
  actStrutturaVanoDocciaSetData,
  actGeneratoreSetModel,
  actGeneratoreSetOptionals
})(withTranslation()(StrutturaVanoDoccia));
